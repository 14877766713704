
// There are 3 routes for viewers.
// viewer-lite - the stable cornerstone-based lite viewer
// viewer-litev3 - the new ohif-based lite viewer
// viewer - the full viewer. with the selection of stable vs lite implemented withing viewer.vue


let routes = [
  { path: "/", component: () => import("./components/Worklist.vue") },
  { path: "/logout", component: () => import("./components/Logout.vue") },
  {
    path: "/mgmt_reports",
    component: () => import("./components/MgmtReports.vue"),
  },
  { path: "/report", component: () => import("./components/ReportWindow.vue") },
  {
    path: "/viewer",
    component: () => import("./components/Viewer.vue"),
    props: {viewerType: 'full'}
  },
  {
    path: "/viewer_lite",
    component: () => import("./components/ViewerLite.vue"),
    props: {viewerType: 'lite'}
  },
  {
    path: "/viewer_litev3",
    component: () => import("./components/Viewer.vue"),
    props: {viewerType: 'lite'}
  },
  {
    path: "/viewer_local",
    component: () => import("./components/ViewerLocal.vue"),
  },
  { path: "/worklist", component: () => import("./components/Worklist.vue") },
  {
    path: "/user_administration",
    component: () => import("./components/UserAdministration.vue"),
  },
  {
    path: "/create_user",
    component: () => import("./components/CreateUser.vue"),
  },
  {
    path: "/edit_user",
    component: () => import("./components/EditUser.vue"),
  },
  {
    path: "/clone_user",
    component: () => import("./components/CloneUser.vue"),
  },
];

// SP-376 - for testing only
//
//if (process.env.NODE_ENV !== 'production') {
//    routes.push({ path: '/user_simulator', component: () => import('./components/MultiUserSimulator.vue') })
//}

export default routes;
